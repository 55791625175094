export const SMS_CONTENT: SmsContent = {
  sv: {
    reminderSms:
      'Hej! Vad kul att du påbörjat din ansökan. Fullfölj alla delar, även video-delen, för en komplett ansökan. Hoppas vi ses!',
    thankYouMessage: 'Tack',
    thankYouMainMessage: 'Din ansökan har nu framgångsrikt blivit inskickad. Vi hör av oss, lycka till!',
    thankYouMainMessageUniversalJob: 'Tack för att du har skickat in din spontanansökan! Vi sparar din information och kontaktar dig om en passande tjänst blir tillgänglig. Lycka till!',
    introMessage: 'Ansökningsprocess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hej! Kul att du är intresserad av att jobba med oss. Slutför stegen nedan för en komplett ansökan – det tar bara några minuter!\n\nObs! Du kan inte gå tillbaka till tidigare frågor, så läs noga innan du svarar.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Hej! Vad kul att du är intresserad av att jobba hos oss. Följ stegen nedan för att skicka in din spontanansökan – det tar bara några minuter!\n\nDu kan inte gå tillbaka till tidigare frågor, så läs noggrant innan du svarar.',
  },
  en: {
    reminderSms:
      'Hi! Great that you started your application. Complete all sections, including the video, to finish it. Hope to see you soon!',
    thankYouMessage: 'Thanks',
    thankYouMainMessage: 'Your application has now been successfully submitted. We will contact you, good luck!',
    thankYouMainMessageUniversalJob: 'Thank you for submitting your open application! We will keep your information on file and contact you if a suitable position becomes available. Good luck!',
    introMessage: 'Application process',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hi! Great that you’re interested in working with us. Complete the steps below for a complete application – it only takes a few minutes!\n\nNote! You can’t go back to previous questions, so read carefully before answering.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Hi! Great that you\'re interested in working with us. Complete the steps below to submit your open application – it only takes a few minutes!\n\nNote! You can’t go back to previous questions, so read carefully before answering.',
  },
  no: {
    reminderSms:
      'Hei! Så gøy at du har startet søknaden din. Fullfør alle deler, inkludert video-delen, for en komplett søknad. Håper vi ses!',
    thankYouMessage: 'Takk',
    thankYouMainMessage: 'Søknaden din er nå sendt inn. Hvis det oppstår en tjeneste som vi tror passer deg, tar vi kontakt!',
    thankYouMainMessageUniversalJob: ' Takk for at du har sendt inn din spontan-søknad! Vi vil lagre informasjonen din og kontakte deg hvis en passende stilling blir tilgjengelig. Lykke til! ',
    introMessage: 'Søknadsprosess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hei! Så gøy at du er interessert i å jobbe med oss. Fullfør stegene nedenfor for en komplett søknad – det tar bare noen minutter!\n\nMerk! Du kan ikke gå tilbake til tidligere spørsmål, så les nøye før du svarer.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Hei! Så gøy at du er interessert i å jobbe hos oss. Fullfør stegene nedenfor for å sende inn din spontane søknad – det tar bare noen minutter!\n\nOBS! Du kan ikke gå tilbake til tidligere spørsmål, så les nøye før du svarer.',
  },
  de: {
    reminderSms:
      'Hallo! Schön, dass du deine Bewerbung begonnen hast. Fülle alle Teile aus, auch das Video, um sie abzuschließen. Hoffe wir sehen uns!',
    thankYouMessage: 'Vielen Dank',
    thankYouMainMessage:
      'Ihre Bewerbung wurde nun erfolgreich eingereicht. Wir werden uns mit Ihnen in Verbindung setzen und wünschen viel Glück!',
    thankYouMainMessageUniversalJob: 'Vielen Dank für die Einreichung deiner spontanen Bewerbung! Wir speichern deine Informationen und kontaktieren dich, wenn eine passende Stelle verfügbar wird. Viel Glück!',
    introMessage: 'Bewerbungsprozess',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hallo! Super, dass du Interesse hast, mit uns zu arbeiten. Vervollständige die Schritte unten für eine vollständige Bewerbung – es dauert nur ein paar Minuten!\n\nAchtung! Du kannst nicht zu vorherigen Fragen zurückkehren, also lies genau, bevor du antwortest.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Hallo! Toll, dass du dich für eine Mitarbeit bei uns interessierst. Fülle die folgenden Schritte aus, um deine Initiativbewerbung einzureichen – das dauert nur wenige Minuten!\n\nACHTUNG! Du kannst nicht zu vorherigen Fragen zurückkehren, also lies sorgfältig, bevor du antwortest.',
  },
  da: {
    reminderSms:
      'Hej! Fedt at du er gået i gang med din ansøgning. Fuldfør alle dele, også video-delen, for en fuld ansøgning. Håber vi ses!',
    thankYouMessage: 'Tak',
    thankYouMainMessage: 'Din ansøgning er sendt. Vi kontakter dig, held og lykke!',
    thankYouMainMessageUniversalJob: 'Tak for at du har sendt din spontanansøgning! Vi opbevarer dine oplysninger og kontakter dig, hvis en passende stilling bliver ledig. Held og lykke!',
    introMessage: 'Ansøgningsproces',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hej! Fedt at du er interesseret i at arbejde med os. Fuldfør trinnene nedenfor for en fuld ansøgning – det tager kun få minutter!\n\nBemærk! Du kan ikke gå tilbage til tidligere spørgsmål, så læs grundigt, før du svarer.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Mahtavaa, että olet kiinnostunut työskentelemään meillä. Täytä alla olevat vaiheet lähettääksesi avoimen hakemuksen – se vie vain muutaman minuutin!\n\nHUOM! Et voi palata aiempiin kysymyksiin, joten lue huolellisesti ennen vastaamista.',
  },
  fi: {
    reminderSms:
      'Hei! Hienoa, että olet aloittanut hakemuksesi. Täytä kaikki osiot, myös video-osio, jotta hakemuksesi on täydellinen. Nähdään!',
    thankYouMessage: 'Kiitos',
    thankYouMainMessage: 'Hakemuksesi on nyt lähetetty onnistuneesti. Otamme sinuun yhteyttä, onnea matkaan!',
    thankYouMainMessageUniversalJob: 'Kiitos, että olet lähettänyt spontaanihakemuksesi! Säilytämme tietosi ja otamme sinuun yhteyttä, jos sopiva tehtävä tulee avoimeksi. Onnea matkaan!',
    introMessage: 'Hakuprosessi',
    // eslint-disable-next-line max-len
    introMainMessage: 'Hei! Mahtavaa, että olet kiinnostunut työskentelemään kanssamme. Alla näet eri vaiheet. Hakemuksen täyttäminen kestää vain muutaman minuutin.\n\nHuom! Et voi palata aiempiin kysymyksiin. Käytä siis aikaa kysymyksen ja vastausvaihtoehtojen huolelliseen lukemiseen ennen vastaamista.',
    // eslint-disable-next-line max-len
    introMainMessageUniversalJob: 'Hej! Fedt at du er interesseret i at arbejde med os. Fuldfør trinnene nedenfor for en fuld ansøgning – det tager kun få minutter!\n\nBemærk! Du kan ikke gå tilbage til tidligere spørgsmål, så læs grundigt, før du svarer.',
  },
};

interface SmsContent {
  [key: string]: {
    reminderSms: string;
    thankYouMessage: string;
    thankYouMainMessage: string;
    introMessage: string;
    introMainMessage: string;
    thankYouMainMessageUniversalJob: string;
    introMainMessageUniversalJob: string;
  };
}
