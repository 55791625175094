import {
  Answer,
  BaseHiringStatus,
  IJob,
  IJobPayload,
  JobDetails,
  JobInfo,
  JobStatus,
  PersonalityTest,
  Question,
  Quiz,
  QuizModules,
  TypeOfSkillsQuestion,
  VideoQuestion
} from '../model/job.interface';
import { QUIZ_MODULES, QUIZ_MODULES_ARRAY } from '../resources/quiz-modules';
import { JOB_PLATFORMS_PUBLISH, JobPlatformPublish } from '../resources/job-platforms';
import { JobPublish, PublishingPlatform, PublishingPlatforms } from '../model/publishing-platform.interface';
import { Platforms } from '../model/platform-data-update.interface';
import { TEMPLATE_FIELDS_TO_DISABLE } from '../resources/template-fields-to-disable';
import { User, UserCommunicator } from '../model/user.interface';
import { TranslationKeys } from '../model/translation-object.interface';
import { JOB_TRIGGER_FIELDS, Trigger, TriggerActionTimeTypes } from '../model/trigger.interface';
import { IHiringStatus } from '../model/hiring-status.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { BOUNDED_FIELDS_TO_DISABLE } from '../resources/bounded-job-fields-to-disable';
import { RequisitionClass } from './requisition.class';
import { formatUTCToStringDate } from '../resources/shared-functions';
export class Job {

  protected static isTemplate = false;
  protected static isDraft = false;

  jobInfo: JobInfo;
  jobDetails: JobDetails;
  quizModules: QuizModules;
  jobPublish: JobPublish;
  creator: UserCommunicator;
  disableSurveysForEdit: boolean;
  requisition: RequisitionClass;
  alreadyGeneratedAiQuestions: Question[];

  constructor(job: IJob, language: TranslationKeys, companyName?: string) {
    this.setQuizModules(job, language);
    this.setJobDetails(job);
    this.setJobPlatforms(job);
    this.setJobInfo(job);
    this.creator = job.creatorCustomer || job.creatorEnterpriseManager;
    this.disableSurveysForEdit = job.disableSurveysForEdit;
    if (job.requisition) {
      this.requisition = new RequisitionClass(job.requisition, companyName);
    }
    if (job.aiUsed) {
      this.alreadyGeneratedAiQuestions = job.aiUsed;
    }
  }

  static prepareForPublish(job: Job, language: TranslationKeys, allPlatforms: PublishingPlatforms): IJob {
    const iJob = {
      ...this.prepareJobDetails(job.jobDetails),
      ...this.prepareJobInfo(job.jobInfo),
      ...this.prepareQuizModules(job.quizModules, language),
      ...this.preparePublishPlatforms(job, allPlatforms),
      ...this.prepareRequisition(job.requisition)
    } as IJob;

    // if job is a template we remove excess fields
    if (this.isTemplate) {
      TEMPLATE_FIELDS_TO_DISABLE.forEach((field: string) => delete iJob[field]);
    }

    if (JSON.parse(sessionStorage.getItem('ai'))) {
      iJob.aiUsed = JSON.parse(sessionStorage.getItem('ai'));
    }

    // if job has bounded jobs we remove quiz modules
    if (job.disableSurveysForEdit) {
      BOUNDED_FIELDS_TO_DISABLE.forEach((field: string) => delete iJob[field]);
    }

    return iJob;
  }

  private static prepareJobDetails(jobDetails: JobDetails): Partial<IJob> {
    if (!jobDetails) {
      return;
    }

    if (!!jobDetails.video) {
      jobDetails.picture = null;
    }
    if (!!jobDetails.picture) {
      jobDetails.video = null;
    }

    return jobDetails;
  }

  private static prepareJobInfo(jobInfo: JobInfo): Partial<IJobPayload> {
    const job: Partial<IJobPayload> = {
      jobTitle: jobInfo.jobTitle,
      jobCategory: jobInfo.jobCategory,
      branch: jobInfo.branch,
      jobLocation: jobInfo.jobLocation,
      typeOfEmployment: jobInfo.typeOfEmployment,
      typeOfEmploymentAddition: jobInfo.typeOfEmploymentAddition,
      applicationDate: jobInfo.applicationDate,
      responsibleCustomer: null,
      responsibleEnterpriseManager: null,
      contactDetail: jobInfo.contactDetail,
      language: jobInfo.language,
      jobWorkLocation: jobInfo.jobWorkLocation,
      notificationEnabled: jobInfo.notificationEnabled,
      allowAnonymousFlow: !!jobInfo.allowAnonymousFlow,
      applicationHiringStatusTriggers: this.prepareTriggers(jobInfo.triggers),
      applicationHiringStatusesWhoseTriggersAreIgnored: jobInfo.excludedJobTriggersHiringStatusIds,
      linkedinProfile: jobInfo.linkedinProfile,
      activationDateTime: jobInfo.activationDateTime ? jobInfo.activationDateTime : null,
      ...this.setResponsiblePerson(jobInfo)
    };

    if (jobInfo.status === 'archive') {
      job.status = 'inactive';
      job.archived = true;
    } else if (jobInfo.activationDateTime && !job.status && !this.isDraft) {
      job.status = 'inactive';
    } else if (['active', 'inactive'].includes(jobInfo.status)) {
      job.status = jobInfo.status;
      job.archived = false;
    } else {
      delete job.archived;
    }

    this.isDraft = false;

    return job;
  }

  private static setResponsiblePerson(jobInfo: JobInfo): Partial<IJobPayload> {
    if (!jobInfo.responsiblePerson) {
      return;
    }

    const job: Partial<IJobPayload> = {
      hiddenFromCustomers: [],
      hiddenFromEnterpriseManagers: []
    };

    const { id, roles } = jobInfo.responsiblePerson;

    if (ENTERPRISE_ROLES.includes(roles[0])) {
      job.responsibleEnterpriseManager = id;
    } else {
      job.responsibleCustomer = id;
    }

    jobInfo.hiddenFromUsers
      .forEach((user: User) => {
        if (ENTERPRISE_ROLES.includes(user.roles[0])) {
          job.hiddenFromEnterpriseManagers.push(user.id);
        } else {
          job.hiddenFromCustomers.push(user.id);
        }
      });

    return job;
  }

  public static prepareTriggers(triggers: Trigger[]): Trigger[] {
    return triggers
      .map((trigger: Trigger) => {
        JOB_TRIGGER_FIELDS
          .forEach((value: string, key: string) => {
            if (trigger.actionType !== key) {
              trigger[value] = null;
            }
          });

        if (trigger.actionTimeType === TriggerActionTimeTypes.relativeTime) {
          trigger.actionTimeOfWeek = null;
        } else {
          trigger.relativeActionTime = null;
        }
        if (trigger.actionType === 'exportToAlva') {
          const { newHiringStatusId, scoreLimit, scoreThreshold } = trigger.alvaLabsData;
          if (trigger.nextHiringStatus || !(newHiringStatusId && scoreLimit && scoreThreshold)) {
            delete trigger.alvaLabsData.newHiringStatusId;
            delete trigger.alvaLabsData.scoreLimit;
            delete trigger.alvaLabsData.scoreThreshold;
          }
          if (!trigger.alvaLabsData.testProfileId) {
            delete trigger.alvaLabsData.testProfileId;
          }
        }

        return {
          ...trigger,
          applicationHiringStatus: (trigger.applicationHiringStatus as BaseHiringStatus).id,
          nextHiringStatus: (trigger.nextHiringStatus as BaseHiringStatus)?.id || null
        };
      });
  }

  public static prepareRequisition(jobRequisition: RequisitionClass): { requisition: number } {

    const requsition = {
      requisition: jobRequisition?.id
    };

    return requsition;
  }

  public static setVideoQuestions(videoQuestion: VideoQuestion): string[] {
    const videoQuestions = [];
    const { q1, q2, q3 } = videoQuestion;

    videoQuestions.push(q1);

    if (q2) {
      videoQuestions.push(q2);
    }

    if (q3) {
      videoQuestions.push(q3);
    }

    return videoQuestions;
  }

  private static prepareQuizModules(quizModules: QuizModules, language: TranslationKeys): Partial<IJobPayload> {
    if (!quizModules || !quizModules.orderOfQuestionsModules) {
      return;
    }

    const job: Partial<IJobPayload> = {};
    job.orderOfQuestionsModules = quizModules.orderOfQuestionsModules;

    QUIZ_MODULES_ARRAY.forEach((moduleName: QUIZ_MODULES) => {
      if (job.orderOfQuestionsModules.indexOf(moduleName) < 0) {
        job[moduleName] =
          moduleName === QUIZ_MODULES.PERSONALITY_TEST ? false : null;
        return;
      }

      if (moduleName === QUIZ_MODULES.VIDEO_QUESTIONS) {
        const videoModule: VideoQuestion = quizModules[moduleName];

        job[moduleName] = {
          videoMessage: videoModule.videoMessage,
          videoMainMessage: videoModule.videoMainMessage,
          video: videoModule.video || ''
        };

        videoModule.questions
          .forEach((question: string, index: number) => {
            const questionNumber = 'q' + (index + 1);
            job[moduleName][questionNumber] = question;
          });

        return;
      }

      if (moduleName === QUIZ_MODULES.PERSONALITY_TEST) {
        const personalityModule: PersonalityTest = quizModules[moduleName];

        job[moduleName] = true;
        job.psykometrikaLanguage = personalityModule.psykometrikaLanguage;

        return;
      }

      if (moduleName === QUIZ_MODULES.INHOUSE_QUESTION) {
        job[moduleName] = quizModules[moduleName];

        return;
      }

      if (moduleName === QUIZ_MODULES.BUSINESS_QUESTIONS) {
        quizModules[moduleName].questions
          .forEach((question: Question) => {
            if (!question.maxNumberOfAnswersCandidateCanChoose || question.maxNumberOfAnswersCandidateCanChoose === 1) {
              question.questionType = TypeOfSkillsQuestion.singleAnswerQuestion;
              delete question.maxNumberOfAnswersCandidateCanChoose;
              delete question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
            } else {
              question.questionType = TypeOfSkillsQuestion.multipleAnswersQuestion;
              question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose =
                !!question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
            }
            if (!question.image) {
              delete question.image;
            }

            if (!question.video) {
              delete question.video;
            }

            question.answers.forEach((answer: Answer) => {
              if (!answer.tag) {
                delete answer.tag;
              }

              answer.desirability = answer.desirability || 0;

              if (!question.maxNumberOfAnswersCandidateCanChoose && answer.followUpQuestion) {
                question.questionType = TypeOfSkillsQuestion.questionWithFollowUpQuestions;
                delete answer.desirability;
                delete answer.followUpQuestion.maxNumberOfAnswersCandidateCanChoose;
                delete answer.followUpQuestion.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose;
                delete answer.followUpQuestion.questionType;
                if (!answer.followUpQuestion.image) {
                  delete answer.followUpQuestion.image;
                }

                if (!answer.followUpQuestion.video) {
                  delete answer.followUpQuestion.video;
                }

                answer.followUpQuestion.answers.forEach((followUpAnswer: Answer) => {
                  if (!followUpAnswer.tag) {
                    delete followUpAnswer.tag;
                  }

                  followUpAnswer.desirability = followUpAnswer.desirability || 0;
                });
              }
            });
          });
      }

      const quizModule: Quiz = quizModules[moduleName];

      job[moduleName] = {
        questions: quizModule.questions,
        rejectionMessage: quizModule.rejectionMessage,
        rejectionMessageMain: quizModule.rejectionMessageMain,
      };

      if (moduleName === QUIZ_MODULES.BUSINESS_QUESTIONS) {
        job[moduleName].minPercentageForPass = +quizModule.minPercentageForPass;
        return;
      }

      if (moduleName === QUIZ_MODULES.KNOCKOUT_QUESTIONS) {
        job[moduleName].passAllCandidates = quizModule.passAllCandidates;

        if (job[moduleName].passAllCandidates) {
          quizModule.questions
            .forEach((question: Question) => {
              question.answers
                .forEach((answer: Answer) => answer.correct = true);
            });
        }
      }
    });

    return job;
  }

  private static preparePublishPlatforms(job: Job, allPlatforms: PublishingPlatforms): Partial<IJobPayload> {
    if (!job.quizModules) {
      return;
    }

    const iJob: Partial<IJobPayload> = {};

    const { jobPublish } = job;

    iJob.jobPlatforms = jobPublish.jobPlatforms || [];
    iJob.publishOnHigher = jobPublish.publishOnHigher;

    JOB_PLATFORMS_PUBLISH
      .forEach(({ jobFormName, templateFormName }, key) => {
        const prop = this.isTemplate ? templateFormName : jobFormName;
        const platformId = allPlatforms?.find(platform => platform.name === key)?.id;

        if (iJob.jobPlatforms.includes(platformId)) {
          iJob[prop] = jobPublish[jobFormName];
        } else if (this.isTemplate || this.isDraft) {
          iJob[prop] = null;
        }
      });

    this.isDraft = false;

    // adway job
    if (iJob.adwayJob || iJob.adwayJobTemplate) {
      const adwayJob = iJob.adwayJob || iJob.adwayJobTemplate;
      if (!adwayJob.picture) {
        delete adwayJob.picture;
      }
      if (!adwayJob.video) {
        delete adwayJob.video;
      }
      if (!adwayJob.storyPicture) {
        delete adwayJob.storyPicture;
      }
      if (!adwayJob.storyVideo) {
        delete adwayJob.storyVideo;
      }
      if (!adwayJob.adwayLocationAlternative) {
        delete adwayJob.adwayLocationAlternative;
      }

      if (!this.isTemplate) {
        adwayJob.costCenter = adwayJob.costCenter || null;
      }
    }

    //facebook and instagram job
    if (iJob.facebookAndInstagramJob) {
      iJob.facebookAndInstagramJob.costCenter = iJob.facebookAndInstagramJob.costCenter || null;
    }
    //blocket
    if (iJob.blocketJob) {
      iJob.blocketJob.costCenter = iJob.blocketJob.costCenter || null;
      delete iJob.blocketJob.blocketCategory;
      delete iJob.blocketJob.blocketRegion;
    }

    if (iJob.blocketJobTemplate) {
      delete iJob.blocketJobTemplate.blocketCategory;
    }

    //indeed
    if (iJob.indeedJob) {
      iJob.indeedJob.costCenter = iJob.indeedJob.costCenter || null;
    }
    //dunitori
    if (iJob.duunitoriJob) {
      iJob.duunitoriJob.costCenter = iJob.duunitoriJob.costCenter || null;
    }
    //jobindex
    if (iJob.jobindexJob) {
      iJob.jobindexJob.costCenter = iJob.jobindexJob.costCenter || null;
    }
    //linkedin
    if (iJob.linkedInJob) {
      iJob.linkedInJob.costCenter = iJob.linkedInJob.costCenter || null;
    }
    //higherchannels
    if (iJob.higherChannelsJob) {
      iJob.higherChannelsJob.costCenter = iJob.higherChannelsJob.costCenter || null;
    }

    //finn
    if (iJob.finnJob) {
      delete iJob.finnJob.finnOccupationsGeneral;
    }
    if (iJob.finnJobTemplate) {
      delete iJob.finnJobTemplate.finnOccupationsGeneral;
    }

    // we remove navCategory
    if (iJob.navJob) {
      delete iJob.navJob.navCategory;
    }

    if (iJob.navJobTemplate) {
      delete iJob.navJobTemplate.navCategory;
    }

    if (iJob.tikTokJob) {
      delete iJob.tikTokJob.tiktokTitle;
      iJob.tikTokJob.costCenter = iJob.tikTokJob.costCenter || null;
    }

    //we remove ams continent

    if (iJob.amsJob || iJob.amsJobTemplate) {
      const amsJob = iJob.amsJob || iJob.amsJobTemplate;
      delete amsJob.amsContinent;
      delete amsJob.amsCategory;
    }

    //jobnet
    if (iJob.jobnetJob) {
      iJob.jobnetJob.worktimeType.lowestWeeklyWorkingHours = iJob.jobnetJob.worktimeType.lowestWeeklyWorkingHours || null;
      iJob.jobnetJob.worktimeType.highestWeeklyWorkingHours = iJob.jobnetJob.worktimeType.highestWeeklyWorkingHours || null;
      if (iJob.jobnetJob.noFixedWorkplace) {
        delete iJob.jobnetJob.workPlaceAddress;
      }
      delete iJob.jobnetJob.jobnetId;
    }

    if (iJob.jobnetJobTemplate) {
      iJob.jobnetJobTemplate.worktimeType.lowestWeeklyWorkingHours = iJob.jobnetJobTemplate.worktimeType.lowestWeeklyWorkingHours || null;
      iJob.jobnetJobTemplate.worktimeType.highestWeeklyWorkingHours = iJob.jobnetJobTemplate.worktimeType.highestWeeklyWorkingHours || null;
      if (iJob.jobnetJobTemplate.noFixedWorkplace) {
        delete iJob.jobnetJobTemplate.workPlaceAddress;
      }
    }

    return iJob;
  }


  static prepareDraftForPublish(job: Job, language: TranslationKeys, allPlatforms: PublishingPlatforms): IJob {
    this.isDraft = true;
    const iJob: IJob = this.prepareForPublish(job, language, allPlatforms);

    if (JSON.parse(sessionStorage.getItem('ai'))) {
      iJob.aiUsed = JSON.parse(sessionStorage.getItem('ai'));
    }

    if (!this.isTemplate) {
      iJob.isJobTemplateDraft = false;
      return iJob;
    }

    iJob.isJobTemplateDraft = true;

    TEMPLATE_FIELDS_TO_DISABLE.forEach(field => {
      if (field === 'responsiblePerson' ||
        field === 'hiddenFromUsers' ||
        field === 'selectedScheduleDate' ||
        field === 'selectedScheduleTime'
      ) {
        return;
      }

      iJob[field] = null;
    });

    iJob.hiddenFromCustomers = [];
    iJob.hiddenFromEnterpriseManagers = [];

    JOB_PLATFORMS_PUBLISH
      .forEach(({ jobFormName, templateFormName, fieldsToDisable }: JobPlatformPublish) => {
        iJob[jobFormName] = iJob[templateFormName] || null;
        delete iJob[templateFormName];

        if (iJob[jobFormName]) {
          fieldsToDisable.forEach(field => delete iJob[jobFormName][field]);
        }
      });

    return iJob;
  }

  private static convertQuiz(quizModule: Quiz, moduleName: QUIZ_MODULES): Quiz {
    const { rejectionMessage, rejectionMessageMain, questions } = quizModule;

    const quiz: Quiz = {
      rejectionMessage,
      rejectionMessageMain,
      questions
    };

    if (moduleName === QUIZ_MODULES.KNOCKOUT_QUESTIONS) {
      quiz.passAllCandidates = quizModule.passAllCandidates;
    }

    if (moduleName === QUIZ_MODULES.BUSINESS_QUESTIONS) {
      quiz.minPercentageForPass = quizModule.minPercentageForPass;
    }

    return quiz;
  }

  private setJobInfo(job: IJob): void {
    const excludedJobTriggersHiringStatusIds = (job.applicationHiringStatusesWhoseTriggersAreIgnored as IHiringStatus[])
      .map(({ id }: IHiringStatus) => id);

    this.jobInfo = {
      jobTitle: job.jobTitle,
      jobCategory: job.jobCategory?.id || null,
      contactDetail: job.contactDetail,
      language: job.language,
      notificationEnabled: job.notificationEnabled,
      allowAnonymousFlow: job.allowAnonymousFlow,
      responsibleCustomer: job.responsibleCustomer || null,
      responsibleEnterpriseManager: job.responsibleEnterpriseManager || null,
      responsiblePerson: job.responsibleCustomer || job.responsibleEnterpriseManager,
      hiddenFromCustomers: job.hiddenFromCustomers || [],
      hiddenFromEnterpriseManagers: job.hiddenFromEnterpriseManagers || [],
      hiddenFromUsers: [],
      triggers: job.applicationHiringStatusTriggers || [],
      boundedJobIds: job.boundedJobIds,
      id: job.id,
      linkedinProfile: job.linkedinProfile,
      excludedJobTriggersHiringStatusIds,
      activationDateTime: job.activationDateTime
    };

    if (job.guid) {
      this.jobInfo.guid = job.guid;
    }

    if (this.jobInfo.isTemplate) {
      return;
    }

    this.jobInfo.hiddenFromUsers = this.setHiddenFromUsers();

    this.jobInfo.isTemplate = false;
    TEMPLATE_FIELDS_TO_DISABLE.forEach(field => {
      if (field === 'applicationDate' ||
        field === 'responsiblePerson' ||
        field === 'hiddenFromUsers' ||
        field === 'linkedinProfile' ||
        field === 'allowAnonymousFlow' ||
        field === 'activationDateTime' ||
        field === 'selectedScheduleDate' ||
        field === 'selectedScheduleTime'
      ) {
        return;
      }

      this.jobInfo[field] = job[field] ? job[field].id : null;
    });

    // we get here UTC date, but job functions only on dates, so we need just to cut date from string
    this.jobInfo.applicationDate = job.applicationDate ? formatUTCToStringDate(job.applicationDate) : null;
    this.jobInfo.status = job.archived ? 'archive' : job.status;
  }

  setHiddenFromUsers(): Partial<User>[] {
    const hiddenFromCustomers = this.jobInfo.hiddenFromCustomers || [];
    const hiddenFromEnterpriseManagers = this.jobInfo.hiddenFromEnterpriseManagers || [];

    return [...hiddenFromCustomers, ...hiddenFromEnterpriseManagers];
  }

  private setQuizModules(job: IJob, language: TranslationKeys): void {
    this.quizModules = {
      orderOfQuestionsModules: job.orderOfQuestionsModules
    };

    job.orderOfQuestionsModules
      .forEach((moduleName: QUIZ_MODULES) => {
        switch (moduleName) {
          case QUIZ_MODULES.PERSONALITY_TEST:
            this.quizModules.personalityTest = {
              psykometrikaLanguage: job.psykometrikaLanguage
            };
            break;
          case QUIZ_MODULES.INHOUSE_QUESTION:
            this.quizModules[moduleName] = job[moduleName];
            break;
          case QUIZ_MODULES.KNOCKOUT_QUESTIONS:
            this.quizModules[moduleName] = Job.convertQuiz(job[moduleName], moduleName);
            break;
          case QUIZ_MODULES.BUSINESS_QUESTIONS:
            this.quizModules[moduleName] = Job.convertQuiz(job[moduleName], moduleName);
            break;
          case QUIZ_MODULES.VIDEO_QUESTIONS:
            this.quizModules.videoQuestions = { ...job.videoQuestions };
            this.quizModules.videoQuestions.questions = Job.setVideoQuestions(job.videoQuestions);

            break;
        }
      });
  }

  private setJobDetails(job: IJob): void {
    this.jobDetails = {} as JobDetails;
    this.jobDetails.picture = job.picture;
    this.jobDetails.video = job.video;
    this.jobDetails.sendReminderSms = job.sendReminderSms;
    this.jobDetails.jobDescription = job.jobDescription;
    this.jobDetails.shortJobDescription = job.shortJobDescription;
    this.jobDetails.jobDescriptionTemplate = job.jobDescriptionTemplate;
    this.jobDetails.shortJobDescriptionTemplate = job.shortJobDescriptionTemplate;
    this.jobDetails.reminderSms = job.reminderSms;
    this.jobDetails.thankYouMessage = job.thankYouMessage;
    this.jobDetails.thankYouMainMessage = job.thankYouMainMessage;
    this.jobDetails.introMainMessage = job.introMainMessage;
    this.jobDetails.introMessage = job.introMessage;
  }

  private setJobPlatforms(job: IJob): void {
    this.jobPublish = {
      publishOnHigher: job.publishOnHigher
    };

    if (job.jobPlatforms) {
      if (job.status === JobStatus.inactive) {
        this.jobPublish.jobPlatforms = [];
      } else {
        this.jobPublish.jobPlatforms = job.jobPlatforms
          .map((platform: PublishingPlatform) => platform.id);
      }
    }

    if (job.publishedJobPlatforms) {
      this.jobPublish.publishedJobPlatforms = job.publishedJobPlatforms
        .map((platform: PublishingPlatform) => platform.id);
    }

    JOB_PLATFORMS_PUBLISH
      .forEach(({ jobFormName, templateFormName }: JobPlatformPublish) => {
        const platform = job[jobFormName] ? jobFormName : templateFormName;
        this.jobPublish[jobFormName] = job[platform] ? this.formatPlatform(job[platform]) : null;
        if (platform === 'adwayJob' && !!job[platform]) {
          this.jobPublish.publishedOnAdway = true;
        }
      });
  }

  private formatPlatform(platform: Platforms): Platforms {
    const plat = {} as Platforms;

    Object
      .keys(platform)
      .forEach((property: string) => {

        if (property === 'createdAt') {
          return;
        }
        if (property === 'navOccupations' && platform['navOccupations'].length) {
          plat['navCategory'] = platform['navOccupations'][0]['parent'].id;
        }

        if (property === 'amsJobLocationCountry') {
          plat['amsContinent'] = platform['amsJobLocationCountry'] !== null ? platform['amsJobLocationCountry']['amsContinent'].id : null;
        }

        if (property === 'finnOccupationSpecializations') {
          const finnOccupationsGeneral = [];
          platform['finnOccupationSpecializations'].forEach(occup => {
            if (!finnOccupationsGeneral.includes(occup.finnOccupationGeneral.id)) {
              finnOccupationsGeneral.push(occup.finnOccupationGeneral.id);
            }
          });
          plat['finnOccupationsGeneral'] = finnOccupationsGeneral;
        }
        if (Array.isArray(platform[property]) && property !== 'navCategories') {
          plat[property] = platform[property].map((child) => child.id);
          return;
        }

        if (property === 'boost') {
          plat[property] = platform[property];
          return;
        }

        if (platform[property]?.hasOwnProperty('id')) {
          plat[property] = platform[property].id;
          return;
        }

        plat[property] = platform[property];
      });
    return plat;
  }
}

export class JobTemplate extends Job {

  static isTemplate = true;

  constructor(job: IJob, language: TranslationKeys) {
    super(job, language);
    this.jobInfo.isTemplate = true;
  }
}
