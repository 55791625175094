interface VideoIntro {
  [key: string]: {
    videoMessage: string;
    videoMainMessage: string;
  }
}

export const maxVideoMessageLength = 100;
export const maxVideoMainMessageLength = 500;

export const VIDEO_INTRO_CONTENT: VideoIntro = {
  sv: {
    videoMessage: 'Nu är du nästan klar!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'För att öka dina chanser i rekryteringsprocessen får du nu möjlighet att svara på några korta videofrågor. Oroa dig inte för inspelningskvaliteten – vi är bara nyfikna på dig. När din ansökan är klar kan du se dina testresultat. Du får 30s på dig per video. Lycka till!'
  },
  en: {
    videoMessage: 'You\'re almost there!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'To increase your chances in the recruitment process, you now have the opportunity to answer a few short video questions. Don’t worry about the recording quality – we’re just curious about you. Once your application is complete, you can see your test results. You have 30 seconds per video. Good luck!'
  },
  no: {
    videoMessage: 'Nå er du nesten ferdig!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'For å øke sjansene dine i rekrutteringsprosessen får du nå muligheten til å svare på noen korte videospørsmål. Ikke bekymre deg for opptakskvaliteten – vi er bare nysgjerrige på deg. Når søknaden din er fullført, kan du se testresultatene dine. Du har 30 sekunder per video. Lykke til!'
  },
  de: {
    videoMessage: 'Du bist fast fertig!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Um deine Chancen im Rekrutierungsprozess zu erhöhen, hast du jetzt die Möglichkeit, einige kurze Videofragen zu beantworten. Mach dir keine Sorgen über die Aufnahmequalität – wir sind einfach nur neugierig auf dich. Sobald deine Bewerbung abgeschlossen ist, kannst du deine Testergebnisse sehen. Du hast 30 Sekunden pro Video. Viel Erfolg!'
  },
  da: {
    videoMessage: 'Nu er du næsten færdig!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'For at øge dine chancer i rekrutteringsprocessen får du nu muligheden for at besvare nogle korte videospørgsmål. Tænk ikke på optagekvaliteten – vi er bare nysgerrige på dig. Når din ansøgning er færdig, kan du se dine testresultater. Du har 30 sekunder per video. Held og lykke!'
  },
  fi: {
    videoMessage: 'Nyt olet melkein valmis!',
    // eslint-disable-next-line max-len
    videoMainMessage: 'Parantaaksesi mahdollisuuksiasi rekrytointiprosessissa saat nyt tilaisuuden vastata muutamaan lyhyeen videokysymykseen. Älä huoli videon laadusta – olemme vain uteliaita sinusta. Kun hakemuksesi on valmis, voit nähdä testituloksesi. Sinulla on 30 sekuntia per video. Onnea matkaan!'
  }
};
